@font-face {
    font-family: 'asmila';
    src: url('../font/Asmila.eot?18640522');
    src: url('../font/Asmila.eot?18640522#iefix') format('embedded-opentype'),
    url('../font/Asmila.woff2?18640522') format('woff2'),
    url('../font/Asmila.woff?18640522') format('woff'),
    url('../font/Asmila.ttf?18640522') format('truetype'),
    url('../font/Asmila.svg?18640522#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}