.map-block {
	padding: 20px 0;
	background: $white;

	.map-holder {
		position: relative;
		overflow: hidden;

		&:before {
			content: " ";
			display: block;
			width: 100%;
			padding-top: (500/1200)*100%;
		}
	}

	.map {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}