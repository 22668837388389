.image-block {
	padding: 20px 0;
	background: $white;

	a {
		display: block;
		font-size: 0;
		line-height: 0;
	}

	img {
		width: 100%;
	}
}