.property-data {
	background: $white;
	padding: 20px 0;
}

.property--table {

	border: 0!important;

	th, td {
		border: 0 !important;
		padding: 0.5rem 0.5rem;
	}

	thead {
		background: transparent;
		border: 0 !important;

		th {
			text-align: center;

			&:before, &:after {
				display: none;
			}
		}
	}

	tbody {
		border: 0 !important;

		tr {
			border: 0 !important;
		}

		td {
			text-align: center;
		}

		tr:nth-child(odd) {
			background: #EAEAE7;
		}

		tr:nth-child(even) {
			background: #DCDCD9;
		}

		tr:hover {
			background: #D8D8D0;

			td {
				background: #D8D8D0;
			}
		}
	}

	tfoot {
		border: 0 !important;
	}

}
