html {
	background: $body-background;
}

body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;

}

.content-block {
	background: $white;
	padding: 20px 0;

	.title {
		text-align: center;
	}
}