.footer {

	padding: 20px 0 60px;
	border-top: 100px solid white;

	.foot-note {
		padding: 20px 0;
		text-align: center;
		font-size: 0.75rem;
	}

	.row {
		justify-content: space-between;
	}

	.small-7 {
		@media (max-width: 420px) {
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.column {
		padding-bottom: 20px;
	}

	a {
		color: $brand-color;

		&:hover {
			color: scale-color($brand-color, $lightness: -14%);
		}
	}

	.footer-logo {
		position: relative;
		padding-bottom: 20px;



		a {
			display: block;
			max-width: 100%;
			color: $brand-color;
			position: relative;
			margin-top: -60px;

			&:hover {
				color: scale-color($brand-color, $lightness: -14%);
			}

			img {
				max-width: 80px;
				margin: 0 auto;
			}

			@media screen and (min-width: 64em) {
				padding: 0 12px;
				position: absolute;
				top: -60px;
				margin-top: 0;
			}

		}

		img {
			max-width: 100%;
		}
	}


}