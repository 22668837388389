.hero {
	background: $white;
	padding-bottom: 20px;


	.slides-container {
		position: relative;

		@media (max-width: 480px) {
			padding: 0;
			margin-right: -.46875rem;
			margin-left: -.46875rem;
		}
	}

	.hero-bottom-arrow {
		color: $white;
		position: absolute;
		z-index: 10;
		bottom: -20px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 30px;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		opacity: 0.5;

		&:hover {
			color: get-color('secondary');
			opacity: 1;
		}

		@media (min-width: 680px) {
			font-size: 60px;
			bottom: -10px;

		}
	}

	.hero__item {
		width: 100%;
		position: relative;
		overflow: hidden;

		&:before {
			content: " ";
			display: block;
			width: 100%;
			padding-bottom: (500/1200)*100%;
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			width: 100%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			-ms-transition: all 0.3s;
			-o-transition: all 0.3s;
			transition: all 0.3s;
		}

		&:hover {
			img {
				-webkit-transform: scale(1.1) translate(-50%, -50%);
				-moz-transform: scale(1.1) translate(-50%, -50%);
				-ms-transform: scale(1.1) translate(-50%, -50%);
				-o-transform: scale(1.1) translate(-50%, -50%);
				transform: scale(1.1) translate(-50%, -50%);

			}
		}
	}

	.hero-with-sidebar {
		.line {
			background: get-color(secondary);
			width: 9px;
			position: relative;
			height: 100%;
			margin: 0 auto;
		}
	}

	.hero-links {
		padding-top: 20px;


		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			a {
				display: block;
				color: get-color('primary');

				&:hover, &.active {
					color: get-color('secondary');
				}
			}

			ul {
				padding-left: 10px;
			}
		}


	}
}


.hero-carousel {
	position: relative;
	overflow: hidden;
}

.owl-nav {
	position: absolute;
	top: 50%;
	margin-top: -20px;
	width: 100%;

	> div {
		display: block;
		width: 40px;
		height: 40px;
		background: $body-background;
		position: absolute;
		opacity: 0.5;
		-webkit-transition: all 300ms;
		-moz-transition: all 300ms;
		-ms-transition: all 300ms;
		-o-transition: all 300ms;
		transition: all 300ms;

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			color: $white;
			font-size: 1.5rem;
		}

		&:hover {
			opacity: 1;
		}

	}

	.owl-prev {
		left: 0;
	}

	.owl-next {
		right: 0;
	}

}

.hero-description {
	display: flex;
	flex-grow: 1;

	.line {
		background: get-color(secondary);
		width: 9px;
		flex: 0 0 9px;
		margin: 0 20px 0 12px;
	}

	article {
		padding-top: 20px;
		font-size: 20px;

		h1, h2, h3 {
			font-size: 24px;
			// font-weight: bold;
		}

		h4.prefix {
			margin-bottom: 0;
		}
	}

}