.header {
	background: $white;
	padding: 10px 0 0;

	h1 {

		text-align: center;
		font-size: 0;
		line-height: 0;
		width: 100%;
		display: block;
		margin: 10px 0 40px;

		a {
			display: inline-block;
		}

		img {
			width: 260px;
		}
	}

	.hamburger {
		display: block;
		width: 30px;
		height: 26px;
		padding: 4px;
		position: relative;
		cursor: pointer;
		z-index: 999;
		-webkit-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
		-moz-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
		-ms-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
		transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;

		span {
			display: block;
			width: 23px;
			height: 3px;
			background: get-color(secondary);
			-webkit-transform-origin: center;
			transform-origin: center;
			-webkit-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
			-moz-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
			-ms-transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
			transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
			margin: 0 0 4px;
		}

		&:hover span:nth-child(1) {
			width: 18px;
		}

		&:hover span:nth-child(3) {
			width: 15px;
		}

		&.active {

			span {
				position: absolute;
				top: 11px;
			}

			span:nth-child(1) {
				transform: rotate(45deg);
				-o-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
			}
			span:nth-child(2) {
				display: none;
			}
			span:nth-child(3) {
				transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
			}

			&:hover span:nth-child(1) {
				width: 23px;
			}

			&:hover span:nth-child(3) {
				width: 23px;
			}
		}
	}

	.second-row {
		justify-content: space-between;
		display: flex;
		align-items: center;

		a:last-child {
			padding-right: 12px;

			img {
				width: 56px;

				@media (min-width: 768px) {
					width: 72px;
				}

			}
		}
	}
}

.nav {
	background: get-color(secondary);
	padding: 20px 0;
	color: $white;
	display: block;

	.row {
		justify-content: space-between;
	}

	a {
		color: $white;
		opacity: 0.6;
		-webkit-transition: all 300ms;
		-moz-transition: all 300ms;
		-ms-transition: all 300ms;
		-o-transition: all 300ms;
		transition: all 300ms;

		&:hover,
		&.active {
			opacity: 1;
		}
	}

	h4 {
		//font-weight: bold;
		margin-bottom: 10px;
	}

	.column {
		padding-bottom: 20px;
	}

	ul {
		list-style: none;
		list-style-type: none;
		margin: 0;

		ul {

			li {
				margin-bottom: 5px;
				&:before {
					content: "-";
					opacity: 0.6;
					display: inline-block;
					margin-right: 10px;
				}
			}

		}
	}

}