.contact-form {
	background: $white;
	padding: 20px 0;
	text-align: center;

	.row {
		justify-content: center;
	}

	.alternate-fonts {
		font-family: $alternate-font-family;
		font-size: 32px;
		text-align: center;
	}

	label {
		text-align: left;
	}

	.button {
		display: block;
		width: 100%;
		color: white;
		// background: $body-background;
	}

	.form-container {
		position: relative;
		padding: 0 0 0 100px;
		min-height: 400px;

		.image {
			position: absolute;
			left: 100px;
			top: 50%;
			transform: translate(-100%, -50%);
		}
		
		@media (min-width: 500px) {
			padding: 0 100px;
		}

		@media (min-width: 668px) {
			padding: 0;
			.image {
				left: 0;
			}
		}
	}
}