.cta-block {
	padding: 20px 0;

	&.white {
		background: $white;
	}

	.small-15 {

		padding-top: 20px;
		padding-bottom: 20px;

		img {
			width: 100%;
		}
	}

	h5 {
		margin-bottom: 0;
	}

	.button,
	.button.alert {
		color: $white;
	}
}