.promotions {
	padding: 20px 0;
	background: $white;

	.promotion {
		float: left;
		display: flex;
	}

	.promotion-image {
		margin-right: 20px;
		width: 40%;
	}

	.owl-nav {

		> div {
			background: $white;
		}

		> div i {
			color: $body-background;
		}

		.owl-prev {
			left: -50px;
		}

		.owl-next {
			right: -50px;
		}

	}
}