.two-column-block {
	background: $white;
	padding: 20px 0;

	.title {
		text-align: center;
		// font-weight: bold;
	}

	.row {
		justify-content: space-between;
	}
}