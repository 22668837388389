.hotspots {

	background: $white;
	padding: 40px 0 20px;

	.column, .columns {
		h3 {
			text-align: center;
		}
	}

	.hotspot__thumb {
		font-size: 0;
		line-height: 0;
		display: block;
		margin-bottom: 1.2rem;

		img {
			width: 100%;
		}
	}

	.button.alert {
		color: $white;
	}

}